.main-footer {

	/*position: fixed;*/
	z-index: 0;
	width: 100%;
	left: 0;
	right: 0;
	bottom: 0;

	.footer-social {
		@media (min-width:768px) {
			max-width: 300px;
		}
	}


	.footer-top {

		padding: 80px 0;

		background: var(--footerBanner) no-repeat center top;
		-webkit-background-size: cover;
		background-size: cover;
		position: relative;

		&:before {
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
			position: absolute;
			content: "";
			background-color: rgba(1, 52, 101, .6);
		}

		.wave {
			position: relative;

			&:after {
				width: 100%;
				height: 90px;
				bottom: 0;
				left: 0;
				right: 0;
				position: absolute;
				display: block;
				background-image: var(--footerWave);
				background-repeat: no-repeat;
				background-size: cover;
				content: "";
				background-position: center center;
			}

		}

		.footer-logo {
			padding-top: 13px;

			@media (max-width: 991px) {
				padding-top: 0px;
			}

		}

		@media (max-width: 767px) {
			padding: 30px 0;
		}

		.footer-title {
			color: var(--whiteColor);
		}

		a {
			color: var(--whiteColor);

			&:hover {
				color: var(--whiteColor);
			}
		}

		.footer-social {
			a {
				font-size: 34px;
				margin: 0 15px;
				color: var(--whiteColor);

				&:hover {
					color: var(--orangeColor);
				}
			}
		}
	}

	.footer-bottom {
		background: var(--darkBlueColor);
		padding: 20px 0 15px;
		font-size: 12px;
		position: relative;

		.footer-text {
			font-size: 12px;
			color: var(--whiteColor);
		}

		.footer-link {
			font-size: 12px;
			color: var(--whiteColor);

			&:hover {
				color: rgba(255, 255, 255, .6);
			}
		}

		ul {
			li {
				border-right: 1px solid #fff;
				line-height: 12px;
				padding-right: 15px;
				margin-right: 15px;

				@media (max-width: 575px) {
					margin-bottom: 10px;
				}

				a {
					display: block;
				}

				&:last-child {
					border-right: none;
				}
			}
		}

		/*

		p {
			color: var(--whiteColor);
		}

		a {
			color: var(--whiteColor);

			&:hover {
				color: rgba(255, 255, 255, .6);
			}
		} */

		.back-to-top {
			width: 50px;
			height: 50px;
			border-radius: 100%;
			line-height: 50px;
			text-align: center;
			background: var(--orangeColor);
			display: inline-block;
			vertical-align: top;
			font-size: 35px;
			box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .1);
			position: absolute;
			top: -25px;
			left: 0;
			right: 0;
			margin: 0 auto;
			cursor: pointer;

			@media (max-width: 991px) {
				right: 10px;
				left: auto;
			}

			@media (max-width: 575px) {
				top: -60px;
			}

			.fas {
				vertical-align: top;
				margin-top: 12px;
			}

			&:hover {
				box-shadow: 0px 4px 33px 0px rgba(0, 0, 0, .3);
				color: var(--whiteColor);
			}

		}
	}
}