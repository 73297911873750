.blog-card-v1 {
	margin-bottom: 30px;
	width: 100%;
	box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
	background-color: var(--white);
	padding: 10px;

	&:hover {
		box-shadow: 0 4px 25px 0 rgb(0 0 0 / 50%);
		-webkit-transform: translateX(1px);
		transform: translateX(1px);
	}

	.image {
		height: 330px;
		overflow: hidden;
		position: relative;

		.date {}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.blog-body {
		padding-top: 10px;
	}

	.date {
		position: absolute;
		font-size: 0.85rem;
		background-color: rgba(1, 52, 101);
		padding: 2px 5px;
		color: var(--white);
		bottom: 10px;
		right: 10px;

	}

	.title {
		font-size: var(--blog-card-title-font-size);
		color: var(--blog-card-title-font-color);
		min-height: 60px;
		font-weight: 600;
	}

	.link {
		text-align: right;
	}
}

.blog-container {
	background: var(--greyColor);
}


.blogentry-v1 {
	position: relative;
	z-index: 1;

	.inner-page-box {
		position: relative;

		@media (max-width:767px) {
			padding-top: 40px;
		}
	}

	img,
	.img-thumbnail {
		height: 100% !important;
		padding: 0;
		border: 0;
		border-radius: 0;
		max-width: 1024px;

	}

	.date {

		position: absolute;
		top: 10px;
		right: 15px;
		font-size: 0.875rem;

	}

	.content-block {
		box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
	}

	.img-col {
		position: relative;

		.ribbon {
			top: 0;
			position: absolute;
			left: 0px;
			font-size: 20px;
			font-weight: bold;
			color: var(--white);
			background-color: var(--red);
			padding: 10px;
		}
	}

	.info-col {
		h4 {
			margin: 0;
			padding: 0;
		}

		.title {
			padding: 10px 0px;
			font-size: 1.2rem;
			font-weight: bold;
			display: inline-block;
		}
	}



	.heading1 {
		display: inline-block;

		border-bottom: 1px solid #58261c;
		text-transform: uppercase;
		padding-bottom: 10px;
		margin-bottom: 20px;
	}

	.tag-box {
		margin: 0 0 20px;
	}
}