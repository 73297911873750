.indexpage {

	.home-img-block {
		border-radius: 3px;
		overflow: hidden;
		position: relative;

		&.ferienwohnungen {
			max-height: 400px;
		}

		a {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 3;


			&:after {
				width: 100%;
				height: 120px;
				bottom: 0;
				left: 0;
				right: 0;
				position: absolute;
				opacity: .8;
				content: "";
				background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
				background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
				background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);

				-webkit-transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				transition: all 0.5s ease;
			}

			&:hover:after {
				background-color: rgba(1, 9, 29, .3);
				height: 100%;
			}

			.home-img-block-text {

				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				padding: 20px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				color: #fff;
				font-family: Varela, sans-serif;
				z-index: 4;

				.name {
					font-size: 20px;
				}

				.more {
					font-size: 15px;

					.fas {
						display: inline-block;
						vertical-align: top;
						margin: 4px 0 0 5px;
					}
				}
			}
		}

		&.orange-bg {
			min-height: 253px;

			@media (min-width:320px) {
				min-height: 200px;
			}

			@media (min-width:768px) {
				min-height: 130px;
			}

			@media (min-width:992px) {
				min-height: 170px;
			}

			@media (min-width:1200px) {
				min-height: 253px;
			}


			a {
				&:after {
					opacity: 1;
					background: rgba(255, 147, 44, 1);
					height: 100%;
				}

				&:hover:after {
					background: rgba(255, 147, 44, .7);
				}
			}

			.home-img-block-text {

				@media (min-width:992px) {
					.name {
						font-size: 26px;
						text-align: center;
						line-height: 28px;
						padding-top: 20px;
					}
				}

				@media (min-width:1200px) {
					.name {
						font-size: 40px;
						text-align: center;
						line-height: 42px;
						padding-top: 20px;
					}
				}
			}
		}
	}

	.home-rating-bottom {

		h5,
		p {
			margin: 0;
		}

		p {
			font-size: 12px;
		}

		a {
			width: 70px;
			height: 70px;
			background: var(--greenColor);
			color: var(--whiteColor);
			display: block;
			text-align: center;
			line-height: 70px;
			font-size: 36px;
			border-radius: 8px;
			pointer-events: none;
		}
	}

}