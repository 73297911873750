@import "common.css";
@import "setup.css";

.search-form {

	.form-bx-area,
	.datepicker-trigger,
	.form-group {
		position: relative;

		.fas,
		.far {
			position: absolute;
			right: 1rem;
			bottom: 18px;
			color: var(--defaultColor);
			font-size: 22px;
			display: block;
			line-height: 21px;
		}

		.far,
		.fas {
			@media (max-width: 1170px) {
				right: 8px;
				bottom: 11px;
				font-size: 18px;
			}
		}
	}

	.form-control {

		&.btn-lg,
		&.form-control-lg {
			@media (max-width:1170px) {
				font-size: 13px;
				padding: 10px;
			}
		}
	}

	.form-group {
		>label {
			font-weight: normal;
			font-size: 18px;

			@media (min-width: 992px) {
				color: var(--whiteColor);
				text-shadow: 0 1px 2px rgba(0, 0, 0, 0.7);
			}

		}

		#region-trigger {
			background-color: var(--whiteColor);
		}
	}

	.asd__wrapper--full-screen {
		@media (max-width: 992px) {
			padding-top: 0;
			width: 100%;

		}
	}
}

.pagination {
	margin: 30px 0 50px;

	@media (max-width: 992px) {
		margin: 5px 0 20px;
	}

	li {
		display: inline-block;
		vertical-align: middle;

		a {
			background-color: var(--whiteColor);
			border-radius: 3px;
			color: var(--orangeColor);
			box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .1);
			width: 40px;
			height: 40px;
			display: block;
			margin-right: 10px;
			text-align: center;
			line-height: 40px;

			&:hover {
				background-color: var(--orangeColor);
				color: var(--whiteColor);
			}
		}

		&.active {
			a {
				background-color: var(--orangeColor);
				color: var(--whiteColor);
			}
		}
	}
}


.map-pane {
	width: 100%;

	&.layout-column {


		.no-map {
			.mapconsent {
				@media (min-width:993px) {
					height: 540px;
				}

				@media (max-width:992px) {
					height: 530px;
				}

				@media (max-width:420px) {
					height: 570px;
				}
			}


		}
	}



	.mapnote {
		padding: 0 10px 20px 10px;

		@media (max-width:992px) {
			padding: 15px 0;
		}

	}



	.map-main {


		>.flex {
			width: 100%;
			max-height: 490px;
			height: 100vh;

			@media (max-width:992px) {
				max-height: 457px;
			}

			>div {
				height: 100%;
			}
		}
	}


	.no-map {


		>.flex {
			width: 100%;
			max-height: 767px;
			height: 100vh;

			>div {
				height: 100%;

				.mapconsent {

					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					background: rgba(26, 121, 187, 0.15);
					flex-direction: column;
					padding: 0 15px;


					.mapconsent-button {
						background: var(--darkBlueColor);
						color: #fff;
						border-radius: 5px;
						padding: 0 15px 0 15px;
						height: 36px;
						display: block;
						line-height: 35px;

						&:hover {
							background: var(--orangeColor);
						}
					}
				}
			}
		}

	}

}

.sidebar-search {
	background-color: var(--whiteColor);
	margin-bottom: 30px;
	padding: 5px;
	border-radius: 3px;
	box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .1);

	a:not([href]):not([tabindex]),
	a:not([href]):not([tabindex]):focus,
	a:not([href]):not([tabindex]):hover {
		color: var(--whiteColor);
	}

	.widgt-title {
		display: block;
		position: relative;
		font-size: 18px;
		color: var(--darkBlueColor);

		a {
			font-weight: 400;
			position: absolute;
			right: 0;
			top: 4px;
			font-size: 13px;

			@media (max-width: 1199px) {
				position: static;
			}
		}
	}

	.label-li {
		li {
			margin: 3px 0;
			padding: 0;

			.checkbox {
				padding: 0 0 0 5px;
			}
		}
	}

	.form-control {
		margin-bottom: 10px;
		width: 100%;
		text-align: left !important;
	}
}

.map-view {

	.list-content {
		display: none
	}

	.map-pane {

		width: 100%;

		.header {
			display: none;
		}

		.flex {
			-webkit-box-flex: 1;
			-webkit-flex: 1 1 0;
			-ms-flex: 1 1;
			flex: 1 1 0;
			min-width: 0;
			min-height: 0;
			height: 600px;

			@media (max-width: 991px) {
				height: 450px;
			}

			@media (max-width: 767px) {
				height: 420px;
			}
		}
	}
}

.owl-carousel {
	overflow: hidden;
	height: 100%;

	.owl-stage-outer {
		height: 100%;

		.owl-stage {
			height: 100%;

			.owl-item,
			.item {
				height: 100%;

				a {
					display: block;
					height: 100%;
				}
			}
		}
	}
}

.available-dates {
	padding: 0 25px;
	margin-bottom: 15px;

	.owl-dt-bl {
		text-align: center;

		.dt-box {
			font-size: 13px;
			color: var(--whiteColor);
			letter-spacing: 1px;
			font-weight: bold;
			background-color: var(--secondary);
			padding: 5px;
			text-transform: uppercase;
		}

		.dt-cont-bl {
			padding: 30px 10px;
			background-color: var(--whiteColor);

			h3 {
				font-size: 19px;
				margin-bottom: 0;
				font-weight: 600;
			}
		}
	}


	.owl-nav {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;

		button {
			position: absolute;
			left: 0;
			color: #a4a4a4;

			span {
				font-size: 28px !important;
			}

			&.owl-next {
				position: absolute;
				right: 0;
				left: initial;
			}
		}
	}
}

.search-about-bl {
	background-color: var(--whiteColor);
	margin-bottom: 25px;

	.srch-bl-title {
		border-bottom: 1px solid #E5E5E5;
		padding: 10px 15px;
		text-transform: uppercase;
		font-weight: 600;
		margin: 0;
	}

	.srch-ab-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 15px;
		flex-wrap: wrap;
	}

	.search-rest-left {
		h3 {
			font-size: 25px;
			font-weight: 400;
			margin: 0 0 5px;
		}

		span {
			font-size: 15px;
			color: var(--darkgreyColor);
			margin-bottom: 5px;
		}
	}
}


.unit-srch-li {
	&.list-view {
		.map-main {
			display: none;
		}
	}


	&.map-view {
		.map-pane.layout-column {
			@media (min-width:993px) {
				height: 580px;
			}

			@media (max-width:992px) {
				height: 550px;
				margin-top: 15px;
			}

			@media (max-width:420px) {
				height: 560px;
			}

		}

		.map-main {
			height: 100%;
			margin-bottom: 30px;
		}
	}


	.title-block {
		background: var(--whiteColor);
		box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .1);
		border-radius: 3px;
		padding: 20px;
		width: 100%;
	}

	.results {
		>.row {
			margin: 0;
		}
	}
}


.vo-search-body {

	.result-pane {
		width: 100%;
		padding: 3px;

		color: #565a5c;

		a {
			color: inherit;
		}

		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 0 5px;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 15px;

			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {

		.result-pane,
		.pagination-panel {
			display: none;
		}
	}

	.map-pane {
		/* 		
     background-color: #f5f5f5;
     */

		.header {
			display: none;
			padding: 5px;
			background-color: whiteColor;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;


		}

		.preview-img {
			min-height: 150px;

			margin-bottom: 15px;


			img {
				max-width: 225px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}

	.more-btn-panel {
		padding-bottom: 20px;
		display: none;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
			color: red;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.unit-main-row {
			margin-bottom: 15px;
			box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .1);
			transition: .3s box-shadow ease, .3s transform ease;

			&:hover {
				box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, .5);
				transform: translateX(2px);
			}


			.alternatives {
				padding: 15px;
				position: relative;
				display: block;
				background-color: #fff;
				border-top: 1px solid #e5e5e5;
				color: var(--defaultColor);

				.li-bullet {
					list-style: disc;
					padding-left: 18px;
				}
			}

			.offer-box {
				padding: 15px;
				position: relative;
				display: flex;
				margin: 0;
				align-items: center;
				flex-wrap: wrap;
				background-color: #fff;
				border-top: 1px solid #e5e5e5;
				color: var(--defaultColor);


				border-top: 1px solid #ebebeb;

				@media (max-width:767px) {
					display: block
				}

				.offer-left {
					background: var(--greenColor);
					color: var(--whiteColor);
					padding: 10px 15px;
					border: 1px dashed var(--whiteColor);
					font-size: 16px;
					text-transform: uppercase;
					display: flex;
					align-items: center;
					margin-right: 15px;

					@media (max-width:767px) {
						display: block;
						text-align: center;
						margin-right: 0;
						margin-bottom: 15px
					}
				}

				ul {
					padding: 0;
					list-style: none;
					margin: 0;

					li {
						margin: 0 0 5px;
						line-height: 14px;
						font-size: 14px
					}
				}
			}
		}

		.unit-row {
			width: 100%;
			position: relative;
			padding: 6px;
			background-color: var(--whiteColor);
			border-radius: 3px;

			.img-col {
				position: relative;
			}

			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.img-content {

					.full-img {
						width: 100%;
						height: auto;

					}

					.fav-icon {
						position: absolute;
						top: 10px;
						left: 10px;
						color: var(--defaultColor);
						background: var(--whiteColor);
						padding: 2px 10px;
						font-size: 11px;
						border-radius: 3px;
						font-weight: 600;
						text-transform: uppercase;
						z-index: 9;
						cursor: pointer;

						.fa {
							margin-right: 5px;
						}
					}
				}
			}

			.info-col {
				position: relative;
				padding: 15px 0;
				background-color: var(--whiteColor);

				@media (max-width:767px) {
					padding: 5px 0;
				}

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.unit-title {
					color: var(--darkBlueColor);
				}

				.unit-serv-li {
					li {
						display: inline-block;
						font-size: 13px;
						color: #4A4A4A;
						width: 48%;
						margin-bottom: 10px;

						i {
							margin-right: 5px;
						}
					}
				}


				.unit-place {
					color: var(--defaultColor);

					i.fa {
						color: var(--darkBlueColor);
						margin-right: 5px;
					}
				}

				.infoBlock {
					padding: 10px;
					background: var(--greyColor);
					margin: 15px 0;

					li {
						width: 33.33%;

						.fas {
							color: var(--darkBlueColor);
							margin: 3px 10px 0 0;
						}
					}
				}
			}

			.unit-price-col {

				text-align: left;

				.price-start {
					padding-right: 5px;
					font-size: 12px;
					display: block;
					margin-bottom: 2px;
				}

				.prices {

					.rentRate {

						strong {
							font-size: 25px;
							line-height: 1;
							font-weight: 400;
							color: var(--orangeColor);
						}

						.main-price {
							strong {
								text-decoration: line-through;
								color: var(--red);
							}
						}
					}

					.price {
						font-size: 20px;
					}

					.per-night {
						font-size: 10px;
						margin-top: 2px;
					}
				}
			}
		}

		.unit-btm-dt {
			li {
				display: inline-flex;
				font-size: 14px;
				color: var(--darkgreyColor);
				width: max-content;
				margin-right: 20px;

				i {
					margin-right: 5px;
				}
			}
		}


	}


	.search-result-properties {
		display: block;
		margin: 0 !important;
		padding: 0 !important;

		li {
			font-size: 18px;
			padding-right: 15px;
			display: inline-block;

			.fas {
				color: var(--darkBlueColor);
			}
		}
	}

	.rating-block-search {

		p {
			line-height: 16px;
			margin: 0;

			span {
				display: block;
				font-size: 11px;
				color: #7d7d7d;
			}
		}

		a {
			width: 50px;
			height: 30px;
			background: var(--greenColor);
			color: var(--whiteColor);
			display: block;
			text-align: center;
			line-height: 24px;
			font-size: 18px;
			border-radius: 3px;
			padding: 3px 10px;
			pointer-events: none;
		}
	}

	.sort-row {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.sort-by {

			display: flex;
			align-items: center;

			a {
				color: var(--orangeColor);
			}

			.list-group-item {
				padding: 10px 15px;
			}

			>.box {
				border: none !important;

				.arrow {
					z-index: 2;
				}

				>.body {
					padding: 0;

					.list-group {
						margin-bottom: 0;
					}
				}
			}

			strong {
				width: 45px;
				height: 45px;
				box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px 0px;
				text-align: center;
				line-height: 45px;
				font-size: 28px;
				border-radius: 3px;
				border: 1px solid rgba(125, 125, 125, .2);
				margin: -26px -8px 0 0;
				cursor: pointer;

				.fa-sort-amount-down {
					color: #000;
				}
			}

			.sort-item {
				color: var(--greyColor3);
				font-size: 12px;
				margin: 5px 0;
				background-color: var(--whiteColor);
				padding: 10px 12px;
				border: 1px solid #dcdcdc;
				margin-left: -1px;
				display: inline-block;
			}

		}
	}





	.search-bar {
		padding: 10px 15px;
		border-top: 1px solid var(--greyColor);
		border-radius: 3px;
		background-color: var(--greyColor);

		.my-search-token {
			font-size: 12px;
			border-radius: inherit;
			background-color: var(--darkBlueColor);
			border: none;
			padding: 3px 6px;
			color: var(--whiteColor);

			.close {
				color: var(--whiteColor);
				opacity: 1;
				padding: 0 !important;
				text-shadow: none;
				font-size: 16px;
				position: relative !important;
				left: 2px;
				top: 1px;
				display: inline-block !important;
			}
		}

		&.srch-rest {
			.widt-title {
				font-size: 16px;
				font-weight: 600;
				letter-spacing: 1px;
				margin-top: 0;
			}

			.rest-count {
				color: #a3a3a3;
				font-size: 13px;
				display: block;
				margin-bottom: 5px;
			}

			.text-link {
				font-size: 12px;
				text-transform: uppercase;
				font-weight: 600;
			}
		}

		&:first-child {
			border-top: none !important;
		}



		.btn {
			margin: 2px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	.search-bar-content {
		padding: 15px;

		.filter-icon {
			position: relative;

			select {
				-webkit-appearance: none;
			}

			.fas {
				position: absolute;
				top: 17px;
				right: 15px;
				font-size: 20px;
				opacity: 0.5;
				pointer-events: none;

				@media (max-width:1170px) {
					top: 12px;
					right: 7px;
					font-size: 18px;
				}
			}
		}

	}

	@media (max-width:767px) {

		.list-view {
			.map-pane {
				display: none;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;

			.header {
				display: block;
			}

		}

	}

	@media (min-width:768px) {

		.result-pane {
			width: 750px;
			max-width: 60%;

			.more-btn-panel {
				display: none;
			}


		}


		.results {

			.unit-row {
				@mixin flexlayout row;

				.info-col {
					width: 55%;
					padding: 0 0 0 15px;
				}

				.img-col {
					@mixin flex;
					position: relative;

					.img-content {
						position: absolute;
						overflow: hidden;
						@mixin size100;


						.full-img {
							height: 100%;
							object-fit: cover;

						}
					}

				}

			}
		}



	}



	.toggle-button {
		display: none;

		@media (max-width:992px) {
			position: fixed;
			top: 50vh;
			display: block;
			background-color: var(--secondary);
			padding: 8px 15px;
			z-index: 50;
			left: 0;
			color: var(--whiteColor);
			font-size: 24px;
		}
	}

	.sidebar-search {
		@media (min-width:993px) {
			display: block !important;
		}

		.search-m-form {
			@media (min-width:993px) {
				display: none;
			}



			@media (max-width:992px) {
				background-color: var(--darkblue);
				padding: 15px 0;
				position: relative;
				z-index: 3000;
			}
		}
	}
}

#searchpanel {
	.container {
		width: auto;
	}

	@media (min-width:992px) {
		position: relative;
		display: block;
		overflow: visible;

		.close {
			display: none;
		}

		&.modal {
			z-index: 3 !important;
		}

		&.fade {
			opacity: 1;
		}

		.modal-dialog {
			width: auto;
			-webkit-transform: translateY(0%);
			transform: translateY(0%);
			margin: 0;
			pointer-events: inherit;
		}
	}





	@media (max-width:992px) {
		.search-bar {
			border: 1px solid #eee;
			border-top: none;
		}

		&.modal.fade {
			.modal-dialog {
				-webkit-transform: translateX(-100%);
				transform: translateX(-100%);
				position: fixed !important;
			}

			&.show {
				.modal-dialog {
					-webkit-transform: translateX(0);
					transform: translateX(0);
					pointer-events: inherit;
				}
			}
		}
	}

	.modal-dialog {
		@media (max-width:992px) {
			margin: 0;
			padding: 50px 15px 15px;
			z-index: 20001;
			max-width: 650px;
			width: 100%;
			background-color: var(--greyColor);
			overflow-y: scroll;
			height: 100vh;

			>.close {
				padding: 0 8px 1px;
				position: absolute;
				top: 6px;
				right: 16px;
				z-index: 50;
				opacity: 1;
				font-size: 36px;
			}
		}
	}
}

.inner-page-box {
	padding: 60px;
	background: var(--whiteColor);
	border-radius: 3px;
	box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .1);

	h1 {
		font-size: 28px;
	}

	@media (max-width: 767px) {
		padding: 20px;

		h1 {
			font-size: 23px;
		}
	}
}

.sidebar-rating {
	background-color: var(--whiteColor);
	border-radius: 3px;
	box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .1);
	padding: 15px 10px;

	.rating-block-search {
		a {
			width: 70px;
			height: 70px;
			font-size: 36px;
			line-height: 64px;
		}

		p {
			font-size: 20px;

			span {
				margin-top: 7px;
				font-size: 12px;
				line-height: 14px;
				color: var(--defaultColor);
			}
		}
	}
}

.toggle-button2 {

	.fas {
		margin-right: 5px;
	}
}

.search-offer-info-v1 {
	background-color: #FFF;
	padding: 10px;
	margin-bottom: 20px;
	border-radius: 3px;
	box-shadow: 0 0.1rem 1rem rgb(0 0 0 / 10%);

	.offer-title {
		color: var(--orangeColor);
		font-size: 18px;
		margin-bottom: 10px;
	}

	.offer-hint {
		margin-top: 20px;
	}

}