@import "setup.css";

.top-bar {
	background: var(--darkBlueColor);
	position: relative;
	z-index: 2;

	@media (max-width: 767px) {
		display: none;
	}

	.logodiv {
		min-height: none;
	}


	ul {
		li {
			display: inline-block;
			vertical-align: top;

			a {
				display: block;
				color: var(--whiteColor);
				font-size: 11px;
				line-height: 32px;
				border-right: 1px solid rgba(255, 255, 255, .3);
				padding: 0 15px;

				&:hover {
					background: rgba(125, 125, 125, .2);
				}

				.fas {
					margin-right: 7px;
					color: rgba(255, 255, 255, .3);
				}
			}

			&:first-child {
				a {
					border-left: 1px solid rgba(255, 255, 255, .3);
				}
			}
		}
	}
}

.vo-nav-bar {

	.header-nav-bg {
		background-color: rgba(1, 52, 101, .6);
		padding-top: 25px;
		padding-bottom: 25px;
		position: relative;
		z-index: 12;
		min-height: 137px;

		@media (max-width: 1200px) {
			padding-top: 15px;
			padding-bottom: 20px;
			min-height: inherit;

			z-index: 1;

			.mobileIcon {
				a {
					color: var(--whiteColor);
					font-size: 30px;
				}
			}
		}

		.logo {
			@media all and (max-width: 1200px) {
				max-width: 140px;
			}
		}

		.main-navigation {
			line-height: 87px;

			@media (max-width: 1200px) {
				display: none;
			}

			ul {
				li {
					@media (min-width: 1201px) {
						display: inline-block;
						vertical-align: top;
						margin-right: 15px;
						position: relative;

						&.last {
							margin-right: 0;

							a {
								border: 1px solid var(--whiteColor);
								border-radius: 100px;
								text-transform: uppercase;
								padding: 6px 25px;
								line-height: 22px;
								margin: 22px 0 0 0;

								@media all and (max-width: 1200px) {
									padding: 6px 15px;
								}

								&:hover {
									background: var(--orangeColor);
									border-color: var(--orangeColor);
								}
							}
						}

						a {
							color: var(--whiteColor);
							display: block;

							&.child-menu {

								&:before {
									margin-left: 7px;
									font-family: 'Font Awesome 5 Free';
									content: "\f0d7";
									font-weight: 900;
									float: right;
								}
							}

							&.navofferlink {
								color: #ff932c;
							}
						}

						ul {
							width: 250px;
							position: absolute;
							text-align: right;
							background-color: var(--whiteColor);
							border-radius: 3px;
							line-height: 42px;
							z-index: 9;
							opacity: 0;
							visibility: hidden;
							box-shadow: 0 0.1rem 2rem rgba(0, 0, 0, .15);
							transition: .3s ease;
							right: 0;
							top: 150%;

							&:before {
								right: 10px;
								top: -8px;
								position: absolute;
								border-top: 0px solid;
								border-right: 7px solid;
								border-bottom: 9px solid;
								border-left: 7px solid;
								border-color: transparent transparent #fff transparent;
								content: ""
							}

							li {
								display: block;
								margin: 0;

								a {
									color: var(--defaultColor);
									padding: 0 15px;
									display: block;
									border-bottom: 1px solid var(--greyColor);
									border-radius: 3px;

									&:hover {
										background: var(--greyColor);
										color: var(--orangeColor);
									}
								}
							}
						}

						&:hover ul {
							top: 100%;
							opacity: 1;
							visibility: visible;
						}
					}

					@media (max-width: 1200px) {
						margin-right: 40px;
					}
				}
			}

		}
	}


}

.navclock {
	font-size: 18px;
	position: relative;
	top: 2px;
	margin-right: 5px;
}

.header-search {
	position: absolute;
	z-index: 2;
	width: 100%;

	.form-control {
		background: rgba(255, 255, 255, .9);
		border: none;
		border-radius: 0;
		font-size: 12px;
	}

	.btn {
		border-radius: 0;
	}
}

.mobile-navigation {
	ul.mm-listview {
		@media (max-width:767px) {
			max-height: 75vh;
			overflow: scroll;
			padding-bottom: 35px;
		}
	}
}

/* Responsiv Menu */
@media (max-width: 1200px) {

	.mobile-navigation {
		ul.mm-listview {

			li {

				&.last {
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;

					a {
						border: 1px solid var(--orangeColor);
						border-radius: 100px;
						text-transform: uppercase;
						padding: 10px 30px;
						max-width: 125px;
						font-size: 13px;
						margin: 22px 0 0 0;

						&:hover {
							background: var(--orangeColor);
							border-color: var(--orangeColor);
						}
					}
				}
			}
		}

		.search {
			position: relative;
			z-index: 2;

			.form-control {
				background: rgba(255, 255, 255, .9);
				border: none;
				border-radius: 0;
				font-size: 12px;
			}

			.btn {
				border-radius: 0;
			}
		}

	}

	.mm-menu_offcanvas {
		width: 100vw;
		min-width: 100vw;
		max-width: 100vw;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout {
		-webkit-transform: translate3d(100vw, 0, 0);
		transform: translate3d(100vw, 0, 0);
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}

	.mm-wrapper_opening .mm-page.mm-slideout {
		display: none;

	}

	.mm-wrapper_opened .main-footer {
		display: none;
	}

	.mm-navbar {
		background: none;
		border-bottom: 1px solid rgba(255, 255, 255, .5);
		padding-top: 50px;
		margin-bottom: 20px;
		padding-bottom: 10px;
	}

	.mm-navbar__title>span {
		color: var(--orangeColor);
		font-size: 20px;
	}

	.mm-panel {
		background-image: var(--mobileMenuImg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}

	.mm-listitem {
		text-align: center;
		color: var(--whiteColor);
		font-size: 20px;
		font-family: 'Varela', sans-serif;
	}

	.mm-listitem:after {
		display: none;
	}

	.mm-listitem__btn {
		border: none !important;
		position: absolute;
		right: 0;
		top: 10px;
	}

	.mm-btn:after,
	.mm-btn:before {
		border-color: var(--whiteColor);
	}
}

/*a {
		float: left;
		display: block;
		color: #f2f2f2;
		text-align: center;
		padding: 14px 16px;
		text-decoration: none;
		font-size: 17px;
	}

	.active {
		background-color: #4CAF50;
		color: white;
	}

	.icon {
		display: none;
	}

	.vo-dropdown,
	.input-group {
		float: left;
		overflow: hidden;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}

	.vo-dropdown .dropbtn {
		font-size: 17px;
		border: none;
		outline: none;
		color: white;
		padding: 14px 16px;
		background-color: inherit;
		font-family: inherit;
		margin: 0;
	}

	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
	}

	.vo-dropdown-content a {
		float: none;
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		text-align: left;
	}*/

/*a:hover,
	.vo-dropdown:hover .dropbtn {
		background-color: #555;
		color: white;
	}

	.vo-dropdown-content a:hover {
		background-color: #ddd;
		color: black;
	}


	.vo-dropdown:hover {
		color: red;
	}

	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}*/


/*@media screen and (max-width: var(--navbarbreak)) {

		a:not(:first-child),
		.input-group,
		.vo-dropdown .dropbtn {
			display: none;
		}

		a.icon {
			float: right;
			display: block;
		}
	}*/


/*@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}

	}*/






@media screen and (max-width: var(--navbarbreak)) {
	.vo-nav-bar.opened {
		position: relative;
	}

	.vo-nav-bar.opened .icon {
		position: absolute;
		right: 0;
		top: 0;
	}

	.vo-nav-bar.opened a {
		float: none;
		display: block;
		text-align: left;
	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
	}

	.vo-nav-bar.opened .vo-dropdown-content {
		position: relative;
	}

	.vo-nav-bar.opened .vo-dropdown .dropbtn {
		display: block;
		width: 100%;
		text-align: left;
	}
}

@media screen and (max-width: 480px) {
	.logo {
		position: absolute;
		width: 214px;
		left: 50%;
		margin-left: -107px;
	}

	.logodiv {
		min-height: 57px;
	}
}