:root {
	--introImg: url(RESOURCE/img/home-banner.jpeg);
	--introMobileImg: url(RESOURCE/img/home-banner.Xc_700x650_1_1.jpeg);
	--mobileMenuImg: url(RESOURCE/img/mobile-bg3.jpg);
	--searchPageBanner: url(RESOURCE/img/banner-search.jpg);
	--searchPageBannerFewo: url(RESOURCE/img/banner-fewo.jpg);
	--searchPageBannerHouse: url(RESOURCE/img/banner-house.jpg);
	--searchPageBannerOffers: url(RESOURCE/img/banner-offers.jpg);
	--searchPageBannerFavorits: url(RESOURCE/img/banner-merkzettel.jpg);
	--werWindPageBanner: url(RESOURCE/img/banner-wir.jpg);
	--lageanfahrtPageBanner: url(RESOURCE/img/banner-anreise.jpg);
	--vermietungPageBanner: url(RESOURCE/img/banner-kontakt3.jpg);
	--vermieterPageBanner: url(RESOURCE/img/banner-owner.jpg);
	--ausflugstippsPageBanner: url(RESOURCE/img/banner-ausflug.jpg);
	--zingstPageBanner: url(RESOURCE/img/banner-zingst2.jpg);
	--prerowPageBanner: url(RESOURCE/img/banner-prerow.jpg);
	--haeufigGestelltePageBanner: url(RESOURCE/img/banner-fragen.jpg);
	--impressumPageBanner: url(RESOURCE/img/banner-impressum.jpg);
	--newsletterPageBanner: url(RESOURCE/img/banner-newsletter.jpg);
	--footerBanner: url(RESOURCE/img/footer-banner.jpg);
	--footerWave: url(RESOURCE/img/curve-grey-down5.png);
	--navbarbreak: 768px;
	--defaultColor: #111111;
	--darkBlueColor: #013465;
	--whiteColor: #fff;
	--greyColor: #edf1f6;
	--orangeColor: #ff932c;
	--greenColor: #52a82d;
	--yellowcolor: #fcc700;
	--dt-green: #3c9d46;
	--white: #fff;
	--hover-dt: #337EB6;
	--not-available: #ff932c;

	--unit-cal-not-available-bg: var(--not-available);
	--unit-cal-not-available-color: #fff;

	--unit-cal-disabled-bg: #fff;
	--unit-cal-disabled-color: #b3b3b3;

	--unit-click-not-possible-bg: #06528f;
	--unit-click-not-possible-color: #fff;

	--unit-click-possible-bg: var(--darkBlueColor);
	--unit-click-possible-color: #fff;

	--unit-cal-selected: var(--hover-dt);
	--unit-cal-selection-range: #BAD1E9;
	--unit-cal-selected-border: #fff;
	--unit-cal-text-color: #333;
	--unit-cal-text-color-hover: white;
	--unit-cal-text-color-selected: white;
}