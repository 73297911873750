@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-Bold.eot');
	src: url('RESOURCE/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-ExtraLight.eot');
	src: url('RESOURCE/fonts/Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-ExtraLight.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-ExtraLight.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-ExtraLight.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-ExtraLight.svg#Poppins-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-Light.eot');
	src: url('RESOURCE/fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-Light.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-Light.svg#Poppins-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-Medium.eot');
	src: url('RESOURCE/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-Medium.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-Medium.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-Regular.eot');
	src: url('RESOURCE/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-SemiBold.eot');
	src: url('RESOURCE/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-Thin.eot');
	src: url('RESOURCE/fonts/Poppins-Thin.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-Thin.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-Thin.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-Thin.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-Thin.svg#Poppins-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}

.image-overlay {
	position: relative;

	&:before {
		content: "";
		inset: 0;
		position: absolute;
		background-color: rgba(0, 0, 0, .5);
		z-index: 1;

	}

	h3 {
		position: absolute;
		z-index: 1;
		color: #fff;
		top: 50%;
		transform: translate(0, -50%);
		width: 100%;

		text-align: left;
		padding-left: 20px;
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}

.doc-box {
	text-align: left;
}

@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.leaflet-popup-content {
	width: 220px !important;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.blog-style {
	.description {
		margin-top: 0 !important;
	}
}

.confirmation {
	.fa-check {
		color: var(--orangeColor);
	}

	.text-success {
		color: var(--orangeColor) !important;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;

		span {
			color: red
		}
	}

	label.lrequired {
		font-weight: 700;
	}

	.checkbox {
		label {
			vertical-align: top;
		}
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.contact-map {
	height: 400px;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

/* date picker */
.datepicker-trigger {
	--cal_not_available: #cd7898;

	.asd__day--arrival {
		background: #ff932c;
		background: linear-gradient(135deg, #013465 50%, #ff932c 0) !important;
		opacity: 1 !important;

	}

	.asd__day--departure {
		background: #ff932c;
		background: linear-gradient(135deg, #ff932c 50%, #013465 0) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background-color: var(-hover-dt);
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background-color: var(-hover-dt);
	}

	.asd__day--disabled.asd__day--in-range {
		background: #BAD1E9 !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: rgb(103, 246, 238) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		background-color: var(--dt-green);
		cursor: pointer;
		color: var(--white) !important;

		color:var(--white) button {
			text-decoration: underline;
		}
	}

	.asd__day--enabled {
		background-color: var(--darkBlueColor);
		cursor: pointer;
		color: var(--white) !important;


		button {
			text-decoration: underline;
		}

		&:hover {
			background-color: var(--hover-dt);
		}


		&.asd__day--disabled {
			color: var(--black) !important;

			button {
				text-decoration: none;
				cursor: not-allowed;
			}

		}

		&.asd__day--not-available {
			background: var(--not-available) !important;
			opacity: 1 !important;
			cursor: not-allowed;
			color: var(--white) !important;
		}
	}

	.asd__day--selected {
		background-color: var(--hover-dt) !important;
		color: white !important;
		opacity: 1 !important;

	}

	.asd__day--in-range {
		background: #BAD1E9 !important;
	}

	.asd__selected-date-one {
		background-image: url(RESOURCE/img/date-start.png) !important;
		background-repeat: no-repeat !important;
		background-size: 13px !important;
		background-position: right center !important;
	}

	.asd__selected-date-two {
		background-image: url(RESOURCE/img/date-end.png) !important;
		background-repeat: no-repeat !important;
		background-size: 13px !important;
		background-position: left center !important;
	}

	.asd__day {
		border: 1px solid #CCCCCC !important;
	}

	.asd__month-name {
		color: var(--darkBlueColor);
	}
}

.unit-cal {

	.datepicker-trigger {

		display: inline-block;

		.asd__day--disabled,
		.asd__day--empty {
			opacity: 1.0 !important;
		}

		.asd__day--scope {
			background: var(--unit-click-not-possible-bg) !important;
			color: var(--unit-click-not-possible-color) !important;
		}

		.asd__day--ar-pos {
			background: var(--unit-click-possible-bg) !important;
			color: var(--unit-click-possible-color) !important;
		}

		.asd__day--arrival {
			background: linear-gradient(135deg, var(--unit-click-possible-bg) 50%, var(--unit-cal-not-available-bg) 50%) !important;

			&.asd__day--no-departure {
				background: linear-gradient(135deg, var(--unit-click-not-possible-bg) 50%, var(--unit-cal-not-available-bg) 50%) !important;
				opacity: 1 !important;
			}

		}

		.asd__day--departure {
			background: linear-gradient(135deg, var(--unit-cal-not-available-bg) 50%, var(--unit-click-possible-bg) 50%) !important;

			&.asd__day--no-arrival {
				background: linear-gradient(135deg, var(--unit-cal-not-available-bg) 50%, var(--unit-click-not-possible-bg) 50%) !important;
			}
		}

		.asd__day--not-available {
			background: var(--unit-cal-not-available-bg) !important;
			color: var(--unit-cal-not-available-color) !important;
			opacity: 1 !important;
		}

		.asd__day--disabled.asd--day--in-range {
			background: var(--unit-cal-selection-range) !important;
			opacity: 1 !important;
			border: 1px double var(--unit-cal-selected-border) !important;
		}

		.asd__day--enabled.asd__day--in-range {
			background: var(--unit-cal-selection-range) !important;
			color: var(--unit-cal-text-color-selected) !important;
			opacity: 1 !important;
			border: 1px double var(--unit-cal-selected-border) !important;
		}

		.asd__day--enabled:not(.asd__day--disabled) {
			&:hover {
				background: var(--unit-cal-selected) !important;
				color: var(--unit-cal-text-color-hover) !important;
			}
		}

		.asd__day--disabled.asd__day--hovered {
			background: var(--unit-cal-selection-range) !important;
			opacity: 1 !important;
			border: 1px double var(--unit-cal-selected-border) !important;
			color: var(--unit-cal-text-color-hover) !important;
		}

		.asd__day--enabled.asd__day--hovered {
			background: var(--unit-cal-selection-range) !important;
			opacity: 1 !important;
			border: 1px double var(--unit-cal-selected-border) !important;
			color: var(--unit-cal-text-color-hover) !important;
		}


		.asd__day--selected {
			background: var(--unit-cal-selected) !important;
			color: var(--unit-cal-text-color-selected) !important;
			opacity: 1 !important;
			border: 1px double var(--unit-cal-selected-border) !important;

			/*Arrow display css start*/
			&.asd__selected-date-one {
				position: relative;

				&:before {
					content: "";
					position: absolute;
					border-left: 12px solid transparent;
					border-top: 19px solid var(--unit-cal-selection-range);
					border-bottom: 20px solid var(--unit-cal-selection-range);
					right: 0px;
				}
			}

			&.asd__selected-date-two {
				position: relative;

				&:before {
					content: "";
					position: absolute;
					border-right: 12px solid transparent;
					border-top: 19px solid var(--unit-cal-selection-range);
					border-bottom: 20px solid var(--unit-cal-selection-range);
					left: 0px;
				}
			}
		}

	}

	.legend-cal {
		text-align: center;
		padding: 0;
		margin: 15px 0;

		ul {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;

			@media (max-width:767px) {
				justify-content: center;
			}
		}

		li {
			display: inline-flex;
			align-items: center;
			min-height: 30px;
			padding-right: 15px;
			margin: 5px 0;

			@media (max-width:767px) {
				justify-content: center;
			}

			&:first-child {
				@media (max-width:992px) {
					width: 100%;
				}
			}

			.day {
				width: 24px;
				height: 24px;
				display: inline-block;
				margin: 0 10px;
				border: 1px solid #e4e7e7;

				@media (max-width:992px) {
					width: 15px;
					height: 15px;
					margin: 0 6px 0 0;
				}

				&.day-free {
					background: var(--unit-click-possible-bg);
				}

				&.day-full {
					background: var(--unit-cal-not-available-bg);
				}

				&.day-disabled {
					background: var(--unit-click-not-possible-bg);
				}

				&.day-selected-range {
					background: var(--unit-cal-selection-range);
				}

				&.day-end {
					background: linear-gradient(135deg, var(--unit-click-possible-bg) 50%, var(--unit-cal-not-available-bg) 50%) !important;
				}

				&.day-start {
					background: linear-gradient(135deg, var(--unit-cal-not-available-bg) 50%, var(--unit-click-possible-bg) 50%) !important
				}
			}
		}
	}

}

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

/* Start Custom Css*/

body {
	font-family: 'Poppins', sans-serif;
	color: var(--defaultColor);
	font-size: 14px;
	/*padding-bottom: 400px;*/
}

.cookie-dlg {
	.box {
		.body {
			padding: 22px 15px !important;
		}

		h4 {
			margin-bottom: 0;

		}
	}
}


.box {
	.body {


		.guestbox {
			.gb-td:first-child {
				padding-right: 0 !important;
			}
		}
	}
}

b,
strong {
	font-weight: 700;
}

.h1,
.h2,
.h3,
h1,
h2,
h3,
h4,
h5 {
	font-weight: 400;
	margin-top: 0;
	margin-bottom: 20px;
	color: var(--darkBlueColor);
	font-family: 'Varela', sans-serif;
}

h1,
.h1 {
	font-size: 36px;

	@media (max-width: 991px) {
		font-size: 28px;
	}

	@media (max-width: 767px) {
		font-size: 23px;
	}
}

h2,
.h2 {
	font-size: 24px;

	@media (max-width: 991px) {
		font-size: 22px;
	}

	@media (max-width: 767px) {
		font-size: 19px;
	}
}

h3,
.h3 {
	font-size: 22px;

	@media (max-width: 991px) {
		font-size: 20px;
	}

	@media (max-width: 767px) {
		font-size: 18px;
	}
}

h4,
.h4 {
	@media (max-width: 767px) {
		font-size: 17px;
	}
}

p {
	line-height: 1.8;
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
}

a {
	text-decoration: none;
	color: var(--orangeColor);
	transition: all ease 0.3s;

	&:hover,
	&:focus,
	&.active a {
		text-decoration: none;
		outline: 0 none;
		color: var(--defaultColor);
	}
}

.btn {
	background: var(--orangeColor);
	color: var(--whiteColor);
	border-radius: 3px;
	border: 1px solid var(--orangeColor);
	box-shadow: none;
	transition: all ease 0.3s;
	font-weight: 400;
	text-transform: uppercase;
	padding: 8px 12px;
	font-size: 14px;

	@media (max-width:767px) {
		white-space: inherit;
	}

	&:hover,
	&.focus,
	&.active,
	&:active,
	&:focus,
	&:active:focus {
		background: var(--darkBlueColor);
		color: var(--whiteColor);
		outline: 0 none;
		box-shadow: none;
		border-color: var(--darkBlueColor);
	}

	&.btn-sm {
		padding: 8px 15px;
	}

	&.btn-md {
		padding: 10px 20px;
	}

	&.btn-lg {
		padding: 15px 25px;
		font-size: 16px;
	}

	&.btn-white {
		background: var(--whiteColor);
		color: var(--orangeColor);

		&:hover {
			color: var(--darkBlueColor);
		}
	}
}

ul.list-style {
	margin-bottom: 25px;

	li {
		position: relative;
		margin: 5px 0;
		padding-left: 20px;

		&:before {
			content: "";
			background: #000;
			width: 5px;
			height: 5px;
			border-radius: 100%;
			position: absolute;
			left: 0;
			top: 8px;
		}
	}
}

ul.list-style-01 {

	li {
		position: relative;
		margin: 5px 0;
		padding-left: 20px;

		&:before {
			content: "\f105";
			position: absolute;
			left: 0;
			top: -2px;
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			color: #ff932c;
			font-size: 16px;
		}
	}
}

.grey-bg {
	background: var(--greyColor);
}

.form-control {
	border-radius: 3px;
	box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, .1);

	&.form-control-lg {
		font-size: 16px;
		padding: 14px 1rem;
		height: inherit !important;
	}

	&.btn-lg {
		padding: 14px 1rem;
		font-size: 16px;

		@media (max-width:1024px) {
			font-size: 13px;
			padding: 10px;
		}
	}
}

.brief {
	padding-top: 60px;
	padding-bottom: 60px;

	@media (max-width:991px) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

@media (min-width:1300px) {
	.container {
		max-width: 1230px;
	}
}

@media (min-width:1200px) {
	.container {
		&.container-small {
			max-width: 870px;
		}
	}
}

ul.icon-listing {
	li {
		position: relative;
		padding: 15px 0 15px 65px;
		border-top: 1px solid var(--whiteColor);
		min-height: 75px;

		&:last-child {
			border-bottom: 1px solid var(--whiteColor);
		}

		.fas,
		.far {
			left: 0;
			top: 15px;
			position: absolute;
			display: block;
			font-size: 44px;
			color: var(--darkBlueColor);
			text-shadow: 2px 2px 2px rgba(0, 0, 0, .10)
		}

		strong {
			display: block;
			margin-bottom: 3px;
			font-weight: 600;
		}

	}
}

.curve-grey {
	position: relative;

	&:after {
		width: 100%;
		height: 129px;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		display: block;
		background-image: url(RESOURCE/img/curve-grey.png);
		background-repeat: no-repeat;
		background-size: cover;
		content: "";
		background-position: center center;
	}
}

.curve-white {
	position: relative;
	padding-bottom: 90px;

	&:after {
		width: 100%;
		height: 74px;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		display: block;
		background-image: url(RESOURCE/img/curve-white.png);
		background-repeat: no-repeat;
		background-size: cover;
		content: "";
		background-position: center center;
		display: none;
	}
}

.innerBanner {
	min-height: 450px;
	background: var(--searchPageBanner) no-repeat center top;
	-webkit-background-size: cover;
	background-size: cover;
	position: relative;
	margin-top: -158px;

	@media (max-width: 992px) {
		min-height: 270px;
	}

	@media (max-width: 767px) {
		min-height: 170px;
		margin-top: -130px;

		&:before {
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
			position: absolute;
			background-color: rgba(1, 52, 101, .6);
			content: "";
		}

		&.curve-grey:after {
			height: 65px;
		}
	}

	&.gutschein-banner {
		background: url(RESOURCE/img/banner-gutschein.jpeg) no-repeat center -340px;
		background-size: cover;
	}

	&.fewo-banner {
		background: var(--searchPageBannerFewo) no-repeat center top;
	}

	&.house-banner {
		background: var(--searchPageBannerHouse) no-repeat center top;
	}

	&.offers-banner {
		background: var(--searchPageBannerOffers) no-repeat center top;
	}

	&.favorits-banner {
		background: var(--searchPageBannerFavorits) no-repeat center top;
	}

	&.staticBanner {

		min-height: 415px;

		&.wer-wir-sind-banner {
			background-image: var(--werWindPageBanner);
		}

		&.lageanfahrt-banner {
			background-image: var(--lageanfahrtPageBanner);
		}

		&.vermietungsagentur-banner {
			background-image: var(--vermietungPageBanner);
		}

		&.vermieter-banner {
			background-image: var(--vermieterPageBanner);
		}

		&.ausflugstipps-banner {
			background-image: var(--ausflugstippsPageBanner);
		}

		&.zingst-banner {
			background-image: var(--zingstPageBanner);
		}

		&.prerow-banner {
			background-image: var(--prerowPageBanner);
		}

		&.haeufig-gestellte-banner {
			background-image: var(--haeufigGestelltePageBanner);
		}

		&.impressum-banner {
			background-image: var(--impressumPageBanner);
		}

		&.newsletter-banner {
			background-image: var(--newsletterPageBanner);
		}

	}

	.innerContent {
		position: absolute;
		bottom: 100px;
		left: 0;
		right: 0;
		margin: 0 auto;
		z-index: 9;
	}
}

.asd__mobile-close {
	width: 35px;
	font-size: 24px;
	height: 35px;
	border: none;
	position: absolute;
	top: 7px;
	right: 15px;
	padding: 5px;
	z-index: 2;
	cursor: pointer;
	border-radius: 2px;

	.asd__mobile-close-icon {
		position: relative;
		top: -5px;
	}
}

.checkbox label:after,
.checkbox label:before {
	top: 2px;
}

.unit-list-section {

	.col-lg-4 {
		display: none;

		&:first-child {
			display: block !important;
		}

		&:nth-child(2),
		&:nth-child(3) {
			display: block !important;
		}
	}
}

.breadcrumbs {

	margin-bottom: 30px;

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			display: inline-block;
			vertical-align: top;
			padding: 9px 0;
			line-height: 14px;
			font-size: 13px;
			margin-right: 20px;
			color: var(--whiteColor);

			&:last-child {
				margin-right: 0;
			}

			a {
				display: block;
				position: relative;
				color: var(--whiteColor);

				&:hover {
					color: var(--orangeColor);
				}

				&:before {
					content: '/';
					font-size: 10px;
					position: absolute;
					right: -15px;
					top: 1px;
				}
			}
		}
	}
}



ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

input[type=checkbox]:focus,
input[type=file]:focus,
input[type=radio]:focus {
	outline: 0 none;
}

.checkbox input[type=checkbox]:focus+label:before,
body .checkbox input[type=checkbox]:focus+label:before {
	outline: 0 none;
}

ul.liststyle01 {
	margin: 0;
	padding: 0;
	list-style-type: none;

	&.mb-10 {
		margin-bottom: 10px;
	}

	li {
		padding: 2px 0 0 17px;
		position: relative;
		margin-bottom: 12px;

		&:before {
			font-family: 'Font Awesome 5 Free';
			content: '\f054';
			font-weight: 900;
			font-size: 12px;
			margin-right: 8px;
			position: absolute;
			left: 0;
			top: 4px;
		}

		a {
			display: inline-block;
			vertical-align: top;
		}
	}
}

.l-h-15 {
	line-height: 1.5;
}

.unitListBox {
	padding: 7px;
	background-color: #f9f9f9;
	border-radius: 3px;
	box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .1);
	transition: .3s box-shadow ease, .3s transform ease;
	overflow: hidden;

	&.white-bg {
		background-color: #fff;
	}

	&:hover {
		box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, .5);
		transform: translateX(2px);
	}

	.unitImg {
		position: relative;

		.fav-icon {
			position: absolute;
			top: 10px;
			left: 10px;
			color: var(--defaultColor);
			background: var(--whiteColor);
			padding: 2px 10px;
			font-size: 11px;
			border-radius: 3px;
			font-weight: 600;
			text-transform: uppercase;
			cursor: pointer;

			.fa {
				margin-right: 5px;
			}
		}

		.title-dtl {
			position: absolute;
			left: 10px;
			bottom: 0;
			right: 10px;

			h5 {
				color: var(--whiteColor);
				font-size: 18px;
				margin-bottom: 2px;
				text-shadow: 0 2px 3px rgba(0, 0, 0, .6);
			}

			p {
				color: var(--whiteColor);
				margin: 0;
				color: rgba(255, 255, 255, .8);
				position: relative;
			}

			.price {
				float: left;
				background: #fff;
				padding: 2px 10px;
				border-radius: 3px 3px 0 0;
				margin-top: 10px;
				font-weight: 600;
				color: var(--defaultColor);

				span {
					font-weight: 400;
					font-size: 10px;
					margin-right: 3px;
				}
			}
		}
	}

	.infoBlock {
		padding: 15px 15px;

		li {
			width: 50%;

			.fas {
				color: var(--darkBlueColor);
				margin: 3px 10px 0 0;
			}
		}
	}

	.rating-block {
		background: var(--whiteColor);
		padding: 10px 20px;
		margin: 0 -7px -7px;
	}
}

:focus,
button:focus {
	outline: 0 none;
}

.shadow-none {
	box-shadow: none !important;
}

.owl-nav {

	button {
		width: 50px;
		height: 100%;
		display: inline-block;
		text-align: center;
		position: absolute;
		top: 0;

		@media (max-width:992px) {
			width: 60px;
		}

		&:before {
			font-size: 50px;
			color: var(--whiteColor);
			font-family: 'Font Awesome 5 Pro';
			font-weight: 900;
		}

		span {
			display: none;
		}

		&.owl-prev {
			left: 0;

			&:before {
				content: "\f104";
			}
		}

		&.owl-next {
			right: 0;

			&:before {
				content: "\f105";
			}
		}
	}
}

.owl-thumbs {
	.owl-thumb-item {
		border: none;
		outline: 0 none;
		margin: 0;
		padding: 0;
		float: left;
		margin: 5px 5px 5px 0;

		&.active {
			img {
				opacity: 0.5 !important;
			}
		}
	}



	.userimg {
		width: 68px;
		height: 68px;
		overflow: hidden;
		display: inline-block;
		vertical-align: top;
		position: relative;

		img {
			transform: translate(-50%, -50%);
			position: absolute;
			left: 50%;
			top: 50%;
		}
	}
}

.asd__wrapper--full-screen {
	z-index: 9 !important;
}

@media (min-width: 992px) {
	.column-count-md {
		column-count: 2;
	}
}

.leadTravel {
	font-size: 1.25rem;
	font-weight: 300;
	margin-bottom: 10px;
	margin-top: 30px;
}

@media (min-width:1200px) {
	.container {
		max-width: 1230px;
	}
}

@media (min-width:1500px) {
	.container {
		max-width: 1470px;
	}
}


.popover {
	border: 1px solid #ddd;
	box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
	border-radius: 5px;

	.arrow {
		border-top-color: #ddd;
	}

	.popover-title {
		padding: 8px 14px 0;
		margin: 0;
		font-size: 13px;
		background-color: transparent;
		border-bottom: none;
		border-radius: 5px 5px 0 0;
		color: var(--secondary);
		font-family: var(--d-font);
	}

	.popover-content {
		ul {

			li {
				list-style: none;
				font-size: 11px;
				font-family: var(--d-font);
				color: #7e7e7e;
			}
		}
	}
}

img {
	image-rendering: -webkit-optimize-contrast;
}

.navofferlink {
	color: red;
}

.btn-md {
	min-width: 200px;
}

.img-content,
.unitImg {
	overflow: hidden;
	position: relative;

	.new-text {
		font-size: 16px;
		font-weight: 500;
		text-transform: uppercase;
		line-height: 25px;
		box-shadow: 0 3px 10px -5px #000;
		position: absolute;
		top: 26px;
		right: -40px;
		width: 150px;
		text-align: center;
		z-index: 1;
		color: #fff;
		transform: rotate(45deg);
		background: var(--orangeColor);

	}
}

.openstreetwarning {
	position: relative;
	background-color: #000;
	aspect-ratio: 16/9;
	padding: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: column;


	@media(max-width:500px) {
		padding: 5px;
		font-size: 13px;
	}


	.text {
		text-align: center;
	}


	.button-line {
		text-align: center;
		margin: 10px 0px;


		@media(max-width:500px) {
			margin: 0px 0px;
		}
	}


	a {
		color: var(--color-white);
	}
}