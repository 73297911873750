.searchbar {

	.searchbox {

		label {
			color: var(--whiteColor);
			font-size: 24px;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: var(--whiteColor);
		}

		.fas,
		.far {
			position: absolute;
			right: 30px;
			bottom: 18px;
			color: var(--defaultColor);
			font-size: 22px;
			display: block;
			line-height: 21px;
		}
	}

}

.facilitylisting-box {
	.unit-list-section {
		.col-lg-4 {
			display: block !important;

			@media all and (min-width: 992px) and (max-width: 1199px) {
				flex: unset;
				max-width: 50%;
			}
		}
	}
}

.unitListBox {
	p {
		min-height: 45px;

		@media (max-width: 991px) {
			min-height: auto;
		}
	}
}

.innerBanner {
	.innerContent {
		@media (max-width: 767px) {
			bottom: 60px;
		}
	}
}

.inner-page-box {
	padding: 60px;
	background: var(--whiteColor);
	border-radius: 3px;
	box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .1);

	h1 {
		font-size: 28px;

		i {
			&.fa-clock {
				margin-right: 10px;
			}
		}
	}

	@media (max-width: 767px) {
		padding: 20px;

		h1 {
			font-size: 23px;
		}
	}

	.prozent {
		float: right;
		font-size: 120px;
		font-weight: 900;
		color: #efebeb;
		margin-top: -103px;
	}
}



.location-map {
	iframe {
		max-width: 100%;
		width: 100%;
		height: 400px;
	}

	.mapnote {
		margin: 0 0 15px 0;
		font-size: 14px;
	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 15px;
		height: 450px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		a {
			background: var(--primary);
			color: #fff;
			border-radius: 5px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: var(--secondary);
			}
		}
	}

	.openstreetwarning {
		aspect-ratio: 16/6;

		@media (max-width:767px) {
			aspect-ratio: unset;
		}

		p {
			color: #000;


		}

		a {
			@media (max-width:767px) {
				word-break: break-all;
			}
		}

		ul {
			li {
				color: #000;
			}
		}

		.privacy {
			@media (max-width:767px) {
				margin-bottom: 15px;
			}
		}
	}
}

.faq-box {
	position: relative;
	margin-bottom: 30px;
	padding: 30px 30px 5px;
	background-color: #eef1f5;
	border-radius: 3px;

	.font-size-20 {
		font-size: 20px;
	}
}

.newsletter-form {
	.form-control {
		max-width: 320px;
	}

	.checkbox {
		label {
			font-size: 11px;
		}
	}
}

.rating-block-search {

	p {
		line-height: 16px;
		margin: 0;

		span {
			display: block;
			font-size: 11px;
			color: #7d7d7d;
		}
	}

	a {
		width: 50px;
		height: 30px;
		background: var(--greenColor);
		color: var(--whiteColor);
		display: block;
		text-align: center;
		line-height: 24px;
		font-size: 18px;
		border-radius: 3px;
		padding: 3px 10px;

		&:hover {
			color: var(--whiteColor);
		}
	}
}

.search-result-properties {
	margin: 0 -7px -7px;
	background: var(--whiteColor);
	padding: 20px 20px 10px;

	li {
		font-size: 18px;
		padding-right: 15px;
		display: inline-block;

		.fas {
			color: var(--darkBlueColor);
		}
	}
}

.facility-unit-brief {
	.inner-page-box {
		p {
			margin-bottom: 10px;
		}
	}
}

.facility-unit-owl {
	height: auto !important;

	.owl-stage {
		height: auto !important;
	}

	.owl-item {
		height: auto !important;

		img {
			height: 720px;
			width: 100%;
			object-fit: cover;

			@media (max-width: 991px) {
				height: auto;
			}

		}
	}
}

.offer-box {
	position: relative;
	margin-bottom: 30px;
	padding: 30px 30px 15px;
	background-color: #eef1f5;
	border-radius: 3px;
}



.offer-blog {
	.offer {
		margin-bottom: 15px;
		box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .1);
		transition: .3s box-shadow ease, .3s transform ease;
		padding: 6px;
		background-color: #fff;
		background-color: var(--whiteColor);
		border-radius: 3px;
		width: 100%;
		position: relative;

		@media(min-width:992px) {

			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			flex-direction: row;

		}



		&:hover {
			box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, .5);
			transform: translateX(2px);
		}

		.img-col {
			min-width: 0;
			min-height: 0;
			position: relative;
			height: 350px;

			@media(min-width:992px) {

				-webkit-box-flex: 1;
				flex: 1 1 0;
			}

			@media (max-width: 767px) {
				height: 280px;
			}

			.img-content {
				height: 100%;
				display: inline-block;
				width: 100%;

				.bl-img {
					height: 100%;
					display: inline-block;
					width: 100%;

					.img-thumbnail {
						padding: 0px;
						background-color: #fff;
						border: none;
						border-radius: 0px;
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
			}

			.ribbon {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				overflow: hidden;
				width: 110px;
				height: 110px;
				text-align: right;
			}

			.ribbon span {
				font-size: 16px;
				font-weight: 500;
				color: #fff;
				text-transform: uppercase;
				text-align: center;
				line-height: 25px;
				transform: rotate(-45deg);
				-webkit-transform: rotate(-45deg);
				width: 160px;
				display: block;
				background: #ff932c;
				box-shadow: 0 3px 10px -5px #000;
				position: absolute;
				top: 31px;
				left: -39px;

			}

		}

		.info-col {
			position: relative;
			padding: 0 0 0 15px;
			padding-top: 10px;

			@media(min-width:993px) {
				width: 55%;
			}

			.date {
				margin-top: -10px;
			}

			.description {
				margin-top: 40px;

				p {
					&:first-child {
						display: block;
					}

					display: none;
				}
			}

			.button {

				text-align: right;

				@media(max-width:992px) {
					margin-top: 10px;
					margin-bottom: 10px;
				}

				@media(min-width:992px) {
					position: absolute;
					right: 20px;
					bottom: 20px;
				}
			}
		}
	}
}