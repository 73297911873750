.price-table {
	margin-top: 20px;
	margin-bottom: 5px;


	.price {
		text-align: right;
		white-space: nowrap;
	}

	.service-info {
		font-size: 13px;
		color: #777;

		.season-label {
			font-style: italic;
		}

		td {
			border-top: none;
			padding-top: 0px;
		}
	}

	tr.total {
		font-weight: bold;

		td {
			padding-top: 10px;
		}
	}

	tr.special {
		td {
			border-top: none;
			padding-top: 0px;
		}
	}

	.invalid {
		text-decoration: line-through;
	}

	.special {
		color: red;
	}
}

.price-details-btn {
	font-style: italic;
}

.price-details-btn::after {
	content: " ";
	display: block;
	margin-bottom: 20px;
}