.booking-view {

	.cart-details {

		.unit-details {
			margin-top: 10px;
			padding-left: 20px;

			li {
				margin-left: 0px;
			}
		}
	}

	.pd-form {
		label {
			font-weight: 700;
		}

		label.lrequired {
			font-weight: 700;
		}

	}

	.final-col {
		.checks {
			margin-bottom: 30px;

			@media (max-width: 575px) {
				margin-bottom: 0;
			}

			label {
				font-weight: 700;
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;

		@media (max-width: 575px) {
			margin-bottom: 0;
		}
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;

		@media (max-width: 575px) {
			margin: 15px 0 0;
			width: 100%;
		}
	}


	.pay-online-box {
		min-height: 240px;

		.amount-options-list {
			padding-left: 10px;
		}

		.amount-option-btn {
			list-style-type: none;
		}

		.amount-option {
			border: none;
			font-weight: bold;
			padding: 0;
			margin-bottom: 4px;
			margin-left: 10px;
		}

		.amount-or {
			margin-top: 2px;
			font-weight: normal;
			font-style: italic;
		}

		.bank-detail-box {
			padding-left: 10px;
		}
	}


}