@import "setup.css";

.indexpage {
	margin-top: 0px;

	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.asd__wrapper--full-screen {
		z-index: 99 !important;
	}

	.iheader {

		min-height: 800px;
		background: var(--introImg) no-repeat center;
		-webkit-background-size: cover;
		background-size: cover;
		position: relative;
		padding-bottom: 130px;

		@media (max-width: 991px) {
			padding-bottom: 90px;
			min-height: 600px;
		}

		@media (max-width: 767px) {
			padding-bottom: 100px;
		}


		.intro {
			text-align: center;

			h1 {
				margin: 0;
				text-shadow: 0 1px 2px rgba(0, 0, 0, 0.7);
				color: var(--whiteColor);
			}

		}

	}



	.searchbar {

		@media (max-width: 575px) {
			position: relative;
			z-index: 9;
		}

		.searchbox {

			label {
				color: var(--whiteColor);
				font-size: 24px;
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: var(--whiteColor);
			}

			.fas,
			.far {
				position: absolute;
				right: 30px;
				bottom: 18px;
				color: var(--defaultColor);
				font-size: 22px;
				display: block;
				line-height: 21px;
			}
		}
	}

	.contact-section {
		background-color: #fff !important;
	}






	@media (max-width:767px) {

		.iheader {
			background-image: var(--introMobileImg);
			-webkit-background-size: cover;
			background-size: cover;

			&:before {
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				position: absolute;
				background-color: rgba(1, 52, 101, .6);
				content: "";
			}

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}







}