@import "common.css";

.brief {
	padding-top: 25px;
	padding-bottom: 60px;

	&.pt-30 {
		padding-top: 30px;
	}

	@media (max-width:991px) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

.unit-block {

	.innerBanner {
		background: none;
		min-height: 350px;

		@media (max-width: 767px) {
			min-height: 180px;
		}

		.innerContent {
			bottom: 80px;
		}

		.breadcrumbs {
			margin-bottom: 10px;

			ul {
				li {
					color: var(--defaultColor);

					a {
						color: var(--defaultColor);
					}
				}
			}
		}

		.back-btn {
			position: relative;
			top: 20px;

			@media (max-width: 991px) {
				top: 0;
				margin-top: -10px;
			}
		}

		.innerBannerTitle {
			h1 {
				margin-bottom: 10px;
				color: var(--darkBlueColor);

				@media (max-width: 991px) {
					margin-bottom: 20px;
				}
			}
		}

		.innerBanner-overflow {
			overflow: hidden;
			width: 100%;
			float: left;

			.innerBanner-img {
				background: var(--searchPageBanner) no-repeat center top;
				-webkit-background-size: cover;
				background-size: cover;
				min-height: 450px;
				-webkit-filter: blur(10px);
				-moz-filter: blur(10px);
				-o-filter: blur(10px);
				-ms-filter: blur(10px);
				filter: blur(10px);

				@media (max-width: 767px) {
					min-height: 200px;
				}
			}
		}
	}

	.shortLink {
		background: rgb(237 241 246);
		/*rgba(0, 27, 66, .9);*/
		padding: 15px 0;

		&.affix {
			top: 0;
			z-index: 9;
			width: 100%;
		}

		@media (max-width:991px) {
			padding-bottom: 15px;
		}

		a {
			padding: 7px 15px;
			display: inline-block;
			vertical-align: top;
			background: var(--whiteColor);
			margin: 5px 5px 5px 0;
			border-radius: 5px;
			float: left;

			@media (min-width: 1200px) {
				&:last-child {
					float: right;
				}
			}

			@media (max-width: 1199px) {
				padding: 3px 7px;
			}
		}
	}

	.unit-fet-bl {

		ul {
			margin: 0 0 15px 15px;
			padding: 0;
			list-style-type: none;


			li {
				display: block;
				vertical-align: top;
				padding-right: 10px;
				padding-left: 40px;
				position: relative;
				color: var(--white);
				position: relative;
				height: 38px;

				@media (max-width:767px) {
					float: left;
					width: 100%;
					margin-bottom: 5px;
				}

				span {
					display: inline-block;
				}

				div {
					padding-top: 0;
					float: left;
				}

				>div {
					line-height: 30px;
				}

				.fas {
					float: left;
					width: 30px;
					height: 30px;
					margin-right: 10px;
					background-color: var(--whitetcolor);
					border: 1px solid #CCCCCC;
					border-radius: 5px;
					color: var(--white);
					font-size: 16px;
					text-align: center;
					line-height: 28px;
					position: absolute;
					left: 0;
					top: 0;
				}
			}
		}
	}


	.unitpage-unit {
		.unit-block {

			.unitListBox {}
		}
	}

	.infoBlock {}


	.col-12 {
		>.sidebar-block {
			background-color: var(--whiteColor);
			border: 1px solid var(--darkBlueColor);
			border-radius: 5px;
			margin-bottom: 20px;

			>h3 {
				margin: 0 !important;
				padding: 10px 20px;
				background-color: var(--darkBlueColor);
				font-size: 20px;
				color: var(--whiteColor);
			}

			.sidebar-block-insdie {
				padding: 20px;
			}

			.openstreetwarning {
				aspect-ratio: unset;
				font-size: 13px;

				p {
					color: #000;


				}

				a {
					@media (max-width:767px) {
						word-break: break-all;
					}
				}

				ul {
					li {
						color: #000;
					}
				}

				.privacy {
					@media (max-width:767px) {
						margin-bottom: 15px;
					}
				}
			}
		}
	}


	.unit-view {

		@media (min-width:768px) {
			margin-top: -50px;
		}

		.unit-list-section {
			.owl-item {
				img {
					opacity: .3;
				}

				&.active {
					img {
						opacity: 1;
					}
				}
			}

			.owl-nav {

				button {
					width: 120px;
					height: 100%;
					display: inline-block;
					text-align: center;
					position: absolute;
					top: 0;

					@media (max-width:992px) {
						width: 60px;
					}

					&:before {
						font-size: 50px;
						color: rgba(255, 255, 255, 0.6);
						font-family: 'Font Awesome 5 Pro';
						font-weight: 900;


						@media (max-width:992px) {
							font-size: 36px;
						}
					}

					span {
						display: none;
					}

					&:hover {
						&:before {
							opacity: 1;
							color: rgba(255, 255, 255, 1);
						}
					}

					&.owl-prev {
						color: rgba(252, 199, 0, 1);
						left: 0;

						&:before {
							content: "\f104";
						}
					}

					&.owl-next {
						right: 0;
						color: var(--whitetcolor);

						&:before {
							content: "\f105";
						}
					}
				}


			}

			.item {
				overflow: hidden;
				/*height: 550px;*/

				@media (max-width:992px) {
					/*height: 350px;*/
				}


				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}

		.sidebar-block {
			background: var(--whiteColor);
			float: left;
			width: 100%;

			&.orange-border {
				border-color: var(--orangeColor);

				h3 {
					background-color: var(--orangeColor);
				}
			}

			.price-table {
				.btn-sm {
					padding: 1px 5px;
					font-size: 10px;
				}

				.service-title {
					padding-left: 0;
					padding-right: 0;
				}
			}

			.optionals {
				.btn-sm {
					padding: 1px 5px;
					font-size: 10px;
				}

				.service-title {
					padding-left: 0;
					padding-right: 0;
				}
			}

			&.address-unit {
				background: var(--darkBlueColor);
				margin-bottom: 30px;
				border: 1px solid var(--bluecolor) !important;
				padding: 20px;

				a:hover,
				a.active,
				a.focus,
				a:active,
				a:active:focus,
				a:focus {
					background: #ec8828;
					border-color: #ec8828;
				}

				h3 {
					color: var(--whiteColor);
				}
			}

			img {
				width: 100%;
			}

			&.border-none {
				border: none;
			}

			.backlink {
				margin: -15px 0 10px;
				text-align: center;
			}

			h3 {
				font-size: 18px;
				text-transform: uppercase;
				font-weight: 300;
				margin-top: 0;
				margin-bottom: 15px;
			}

			.unit-place {
				position: relative;
				padding-left: 22px;
				margin-bottom: 15px;
				color: var(--whiteColor);

				i {
					position: absolute;
					left: 0;
					top: 4px;
					color: var(--greencolor);
				}
			}

			.sidebar-contact {
				display: block;
				margin-bottom: 15px;
				position: relative;
				padding: 0 10px 0 70px;
				min-height: 40px;

				i {
					color: var(--greencolor);
					background-color: var(--whiteColor);
					padding: 5px;
					width: 40px;
					height: 40px;
					border-radius: 50%;
					display: -webkit-inline-box;
					display: inline-flex;
					-webkit-box-align: center;
					align-items: center;
					-webkit-box-pack: center;
					justify-content: center;
					position: absolute;
					left: 15px;
					top: 2px;
					box-shadow: 0 0 10px rgba(0, 0, 0, .18);
					font-size: 18px;
				}

				a {
					font-size: 20px;
					letter-spacing: 1px;
					font-weight: 600;
					display: block;
				}
			}

			&.contact-sidebar-block {
				margin-top: 30px;
				border: 1px solid var(--bluecolor);
				padding: 0;

				h3 {
					background: var(--bluecolor);
					padding: 10px 20px;
					color: var(--whiteColor);
					margin-bottom: 10px;
				}

				.sidebar-img {
					padding: 10px 15px;
				}
			}
		}

		.left-unit-block {
			.datepicker-trigger {
				display: inline-block;
			}


			.unit-fet-bl {
				position: relative;

				.stars {
					margin-bottom: 8px;
					position: absolute;
					right: 15px;
					top: 5px;
					color: var(--orangeColor);
				}
			}

			.v-img-gallery {
				margin: 0 -5px;

				.visible-print {
					display: none !important;
				}

				&.unitGallery {
					a {
						display: none;
						padding: 5px;
						height: 150px;

						&:nth-child(2),
						&:nth-child(3) {
							height: 220px;
						}

						@media (max-width: 767px) {
							padding: 5px;
						}

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}

						&:nth-child(1) {
							width: 70%;
							float: right;
							display: block;
							height: 440px;
							overflow: hidden;

							@media (max-width: 767px) {
								width: 100%;
								height: 300px;
							}
						}

						&:nth-child(2),
						&:nth-child(3) {
							width: 30%;
							float: left;
							display: block;

							@media (max-width: 767px) {
								width: 50%;
								height: 140px;
							}
						}

						&:nth-child(4) {
							clear: both;

							@media (max-width: 767px) {
								clear: none;
								height: 140px;
							}
						}

						&:nth-child(4),
						&:nth-child(5),
						&:nth-child(6),
						&:nth-child(7),
						&:nth-child(8) {
							display: block;
							width: 20%;
							float: left;

							@media (max-width: 767px) {
								width: 50%;
								height: 140px;
							}
						}

						&:nth-child(8) {
							position: relative;

							&:before {
								content: "+ mehr";
								position: absolute;
								left: 0;
								right: 0;
								top: 50%;
								bottom: 0;
								text-align: center;
								width: 100%;
								color: #fff;
								font-weight: 600;
								font-size: 13px;
								margin-top: -10px;
								z-index: 1;
								padding: 0 10px;
							}

							&:after {
								content: "";
								position: absolute;
								left: 5px;
								right: 5px;
								top: 5px;
								bottom: 5px;
								background: rgba(0, 0, 0, .5);
								z-index: 0;

								@media (max-width: 767px) {
									top: 5px;
									bottom: 5px;
								}
							}

						}


					}
				}
			}

			.ratings {
				.card-header {
					.stars {
						margin-left: 10px;
						color: var(--orangeColor);

						@media (max-width:767px) {
							margin-left: 0;
						}
					}
				}

				.card-body {
					.stars {
						margin-bottom: 20px;

						.fa-star {
							color: var(--orangeColor);
						}
					}
				}

			}

			.card {
				position: relative;
				background-color: var(--whiteColor);
				background-clip: border-box;
				border: 1px solid rgba(1, 52, 101, .25);
				margin-bottom: 30px;

				.card-header {
					font-family: 'Varela', sans-serif;
					padding: 10px 15px;
					margin-bottom: 0;
					background-color: var(--darkBlueColor);
					border-bottom: 1px solid var(--darkBlueColor);
					color: var(--whiteColor);
					font-weight: 400;
					font-size: 20px;
				}

				.card-body {
					padding: 15px;

					.feedbacks {
						footer {
							color: var(--darkBlueColor);
							font-weight: 400;
						}
					}
				}
			}

			.unit-prop-bl {

				position: relative;

				&.showLess {
					margin-top: 20px;
					margin-bottom: 20px;
					padding-bottom: 25px;

					.showblock {
						display: none;
					}

					/*.card {
						position: relative;
						background-color: #fff;
						background-clip: border-box;
						border: 1px solid var(--lightbluecolor);
						margin-bottom: 30px;

						.card-header {
							padding: 10px 15px;
							margin-bottom: 0;
							background-color: var(--lightbluecolor);
							border-bottom: 1px solid var(--lightbluecolor);
							color: var(--darkbluecolor);
							font-weight: 700;
							font-size: 20px;
						}

						.card-body {
							padding: 15px;
						}
					}*/

				}

				&.showFull {
					.showblock {
						display: block;
					}

					.unit-pro-bl {
						max-height: inherit;
						overflow: visible;

					}
				}

				.show-hide-btn {
					position: absolute;
					bottom: -14px;
					left: 50%;
					-webkit-transform: translate(-50%);
					transform: translate(-50%);
					padding: 5px 15px;
					display: inline-block;
					border: 1px solid rgba(1, 52, 101, .25);
					background: var(--whiteColor);
					border-radius: 0;
					color: var(--darkBlueColor);
					text-transform: none;
					width: 160px;
				}
			}


			.feedbacks {
				max-height: 140px;
				overflow: hidden;
			}



			.backlink {
				text-align: right;
				margin-bottom: 8px;

				.fas {
					color: var(--darkbluecolor);
					margin-right: 3px;
				}
			}

			.legend-cal {
				text-align: center;
				padding: 0;
				margin: 15px 0 0;

				ul {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					justify-content: center;
					margin: 0;
				}

				li {
					display: inline-flex;
					align-items: center;
					min-height: 30px;
					padding-right: 15px;
					margin: 5px 0;
					color: var(--darkBlueColor);

					@media (max-width:767px) {
						&:first-child {
							width: 100%;
						}

						text-align:left;
						width:50%;
					}

					.day {
						width: 30px;
						height: 30px;
						display: inline-block;
						margin: 0 10px;
						border: 1px solid #e4e7e7;

						@media (max-width:767px) {
							margin-left: 0;
						}

						&.day-free {
							background-color: var(--darkBlueColor);
						}

						&.day-full {
							background-color: var(--not-available);
						}

						&.day-start {
							background: #ff932c;
							background: linear-gradient(135deg, #ff932c 50%, #013465 0) !important;
						}

						&.day-end {
							background: #ff932c;
							background: linear-gradient(135deg, #013465 50%, #ff932c 0) !important;
						}

						&.day-no-arrival {
							background-color: var(--unit-click-not-possible-bg);
						}
					}
				}
			}
		}



		.title-img-container {
			width: 100%;
			cursor: pointer;
		}

		.search-section {

			.gb-td {
				padding-left: 0;
			}

			.searchbox {
				margin: 1px;

				&.datepicker-trigger {
					display: block;
				}

				@media (max-width:767px) {
					margin: 0;

					&.datepicker-trigger {
						margin-bottom: 20px;
					}
				}
			}

			.search-popup-trigger {
				cursor: pointer;
				font-size: 14px;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: var(--whiteColor);
			}

		}

		.title-img {

			position: relative;
			width: 100%;
			height: 700px;

			-webkit-background-size: cover;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;


			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 160px;
				position: absolute;
				top: 50%;
				margin-top: -57px;
			}

			.v-left-gallery-btn:hover,
			.v-right-gallery-btn:hover {
				color: #585858;
			}

			.v-right-gallery-btn {
				right: 40px;
			}

			.v-left-gallery-btn {
				left: 40px;
			}

		}



		.panorama {
			position: relative;
			width: 100%;
			height: 700px;
		}



		.main-section {
			position: relative;

			max-width: 1200px;

			h2 {
				margin-top: 40px;
				font-size: 22px;

				.stars {
					display: inline-block;
					margin-left: 22px;
					color: #dfb62d;
				}
			}

			.main-col {

				position: static;

				.stars {
					margin-top: 10px;
				}

				.description {
					padding: 15px 15px 5px 15px;
				}

				.backlink {
					margin-top: 20px;
				}


				.intro {
					i {
						font-size: 26px;
					}

					>div {
						text-align: center;
					}
				}

				.properties {
					margin-left: 15px;
					margin-right: 15px;
					@mixin columnlayout 2;

					.prop.bool {
						font-weight: bold;
					}

					.prop.missing {
						text-decoration: line-through;
						font-weight: normal;
					}

					.stars {
						display: inline-block;
						color: #dfb62d;
					}
				}

				.feedbacks {
					margin-top: 30px;

					blockquote {
						div {
							font-family: 'Indie Flower', cursive;
						}

					}
				}


			}

			@media (min-width:768px) {
				.info-col.noaction {
					position: absolute;
					right: 0;
					top: -41px;

					width: 33.33333333%;
					/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */

					.info-col-header {
						height: 40px;
						padding: 1px;
						background-color: rgba(60, 63, 64, 0.9);
						color: #fff;
						text-align: center;

						h2 {
							margin-top: 10px;
							font-size: 18px;
						}
					}

					.info-col-main {
						border: 1px solid #888;
						border-bottom: none;
						width: 100%;
					}

					.subprice {
						width: 100%;
						border: 1px solid #888;
						border-top: none;
					}

					.fav-section {
						margin-top: 20px;
						border-bottom: 1px solid #888 !important;
						padding: 15px;
					}

				}
			}

			.info-col {

				max-width: 800px;

				.info-col-main {

					padding: 15px 15px 1px 15px;
				}

				.subprice {

					padding: 1px 15px 15px 15px;

					.filters {}


					.book-btn {
						margin-top: 30px;
						margin-bottom: 10px;
					}

					.flow-buttons {
						button {
							margin-left: 20px;
						}
					}
				}




			}

			.price-list {
				max-width: 400px;

				.subline {
					.labelcol {
						padding-left: 20px;
					}

					td {
						border-top: none;
					}
				}

			}
		}


		.subsection {
			width: 100%;

			.unit-map {
				height: 350px;
				width: 100%;



				.mapnote {
					font-size: 14px;
					padding: 0 0 20px;
					background: #fff;

				}

				>div {
					height: 350px;

					@media (max-width:991px) {
						height: 400px;
					}
				}

				&.no-map {
					height: auto;

					.mapconsent {
						height: auto;
						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
						background: rgba(26, 121, 187, 0.15);
						flex-direction: column;
						padding: 15px;



						.doc-box {}

						.mapconsent-button {
							background: var(--darkBlueColor);
							color: #fff;
							border-radius: 5px;
							padding: 0 15px 0 15px;

							display: block;
							line-height: 35px;

							@media (min-width:993px) and (max-width:1199px) {

								padding: 5px 15px;
								display: flex;
								line-height: 15px;
								min-height: 35px;
								align-items: center;
								font-size: 12px;
							}

							&:hover {
								background: var(--orangeColor);
							}
						}
					}

					@media (max-width:420px) {
						height: auto;
					}

					>div {
						height: auto;

						@media (max-width:991px) {
							height: auto;
						}
					}
				}
			}
		}


		.matterport-showcase {
			text-align: center;

			iframe {
				min-height: 240px;
				margin: 16px 0;
				width: 100%;
			}

		}




		@media all and (max-width:767px) {

			.title-img {
				height: 350px;

				.v-left-gallery-btn,
				.v-right-gallery-btn {
					font-size: 80px;
				}

			}

			.panorama {
				height: 350px;
			}


			.page-header h1 {
				font-size: 24px;
			}

			.main-section {

				.main-col {
					.properties {
						@mixin columnlayout 1;
					}
				}
			}

		}


		@media (min-width:768px) and (max-width:992px) {

			.title-img {
				height: 465px;
			}

			.panorama {
				height: 465px;
			}

			.matterport-showcase iframe {
				min-height: 360px;
			}


		}

		@media (min-width:993px) and (max-width:1199px) {

			.title-img {
				height: 580px;
			}

			.panorama {
				height: 580px;
			}

			.matterport-showcase iframe {
				height: 600px;
				max-width: 1068px;
				margin: 0 auto 15px;

			}


		}


		@media screen and (min-width: 993px) {
			.matterport-showcase iframe {
				height: 600px;
				max-width: 1068px;
				margin: 0 auto 15px;

			}

		}


	}

}






.facility-view {

	h2 {
		margin-top: 40px;
		font-size: 22px;
	}

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}

.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}
}

.unit-proposals {
	background-color: #f8f8f8;
}